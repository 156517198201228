<template>
  <div class="main-box">
    <span class="mg-rt-20">订单状态</span>
    <el-radio-group v-model="search.status" border="true" size="small" @input="handleSearch">
      <el-radio-button label="0">全部</el-radio-button>
      <el-radio-button label="1">待付款</el-radio-button>
      <el-radio-button label="2">待发货</el-radio-button>
      <el-radio-button label="3">待收货</el-radio-button>
      <el-radio-button label="4">已完成</el-radio-button>
      <el-radio-button label="5">待评价</el-radio-button>
      <el-radio-button label="10">已取消</el-radio-button>
      <el-radio-button label="20">维权关闭</el-radio-button>
      <el-radio-button label="21">退款中</el-radio-button>
    </el-radio-group>
    <el-form class="form-inline mg-tp-10" :inline="true" size="small">
      <el-form-item label="">
        <el-select v-model="search.order_type" placeholder="请选择订单类型" clearable>
          <el-option
              v-for="item in orderTypeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入订单号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker
            size="small"
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="支付时间">
        <el-date-picker
            size="small"
            v-model="dateRange2"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="order_sn"
          label="订单号"
          fixed="left"
          width="150">
        <template slot-scope="scope">
          <div>{{scope.row.order_sn}}</div>
          <div v-if="scope.row.order_type==1">商品订单</div>
          <div v-if="scope.row.order_type==2">图书订单</div>
          <div v-if="scope.row.order_type==3">兑换订单</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="member_id"
          label="下单用户"
          align="center"
          width="240">
        <template slot-scope="scope">
          <div v-if="scope.row.member" class="member-box">
            <img :src="scope.row.member.avatar" />
            <div>
              <div>{{scope.row.member.name}}（{{scope.row.member.user_id}}）</div>
              <div>{{scope.row.member.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="goods"
          label="商品"
          width="450">
        <template slot-scope="scope">
          <table width="100%" class="gdtable">
            <tr v-for="(item,index) in scope.row.goods" :key="index">
              <td style="padding-bottom: 10px;width: 50px;"><img :src="item.goods_img" style="width: 40px;height: 40px;"></td>
              <td style="text-align: left;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{item.goods_name}} <span v-if="item.specs_name">/ {{item.specs_name}}</span></td>
              <td style="width: 100px; text-align: right">￥{{item.price}} x{{item.quantity}}</td>
            </tr>
          </table>
        </template>
      </el-table-column>
      <el-table-column
          prop="refund_status"
          label="售后"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-button type="text" v-if="scope.row.refund_status==1" size="small" @click="orderRefundDetail(scope.row)">退款申请中</el-button>
          <el-button type="text" v-if="scope.row.refund_status==2" size="small" @click="orderRefundDetail(scope.row)">退款完成</el-button>
<!--          <span v-if="scope.row.refund_status==0">-</span>-->
          <el-button type="text" v-if="scope.row.return_status==1" size="small" @click="orderDetail(scope.row)">还书中</el-button>
          <el-button type="text" v-if="scope.row.return_status==2" size="small" @click="orderDetail(scope.row)">已还书</el-button>
        </template>
      </el-table-column>
      <el-table-column
          prop="goods_amount"
          label="商品总价"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="shipment_fee"
          label="运费"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="实付款"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="订单状态"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="下单时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          prop="address"
          label="收货地址"
          align="left"
          width="190">
        <template slot-scope="scope">
          <span v-if="scope.row.address">{{scope.row.address.name}} {{scope.row.address.mobile}}<br>
            {{scope.row.address.province_name}}{{scope.row.address.city_name}}{{scope.row.address.district_name}}{{scope.row.address.address}}
            <el-button type="text" id="copyId" @click="copyTxt(scope.row.address.name + '，' + scope.row.address.mobile + '，' + scope.row.address.province_name+scope.row.address.city_name+scope.row.address.district_name+scope.row.address.address)" size="small">复制</el-button>
          </span>
        </template>
      </el-table-column>
      <el-table-column
          prop="pay_time"
          label="支付时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">
            <el-popconfirm
                title="确认取消该订单吗？"
                @confirm="orderCancel(scope.row.id)">
              <el-button type="text" size="small" slot="reference">取消</el-button>
            </el-popconfirm>
          </span>
          <span v-if="scope.row.status==1">
            <el-button type="text" size="small" @click="sendDelivery(scope.row)">发货</el-button>
          </span>
          <span class="mg-lf-10">
            <el-button type="text" size="small" @click="orderDetail(scope.row)">详情</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="订单发货"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="600px">
      <order-delivery :orderId="delivery.order_id" :callback="res => { dialogVisible=false; getList() }"></order-delivery>
    </el-dialog>
  </div>
</template>
<script>

import ClipboardJS from "clipboard";
import orderDelivery from "@/components/OrderDelivery";
export default {
  components: {
    orderDelivery
  },
  data() {
    return {
      info: false,
      isOpen: false,
      dialogVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        order_type: '',
        delivery_type: '',
        status: 0,
        order_goods_type: '',
        refund: '',
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
      itemInfo: false,
      delivery: {
        order_id: 0
      },
      dateRange: ['',''],
      dateRange2: ['',''],
      orderTypeList: [
        {id:1,title:'商品订单'},
        {id:2,title:'图书订单'},
        {id:3,title:'兑换订单'},
      ]
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        order_type: that.search.order_type,
        order_goods_type: that.search.order_goods_type,
        delivery_type: that.search.delivery_type,
        refund: that.search.refund
      }
      if(this.dateRange) {
        param.start_time = this.dateRange[0]
        param.end_time = this.dateRange[1]
      }
      if(this.dateRange2) {
        param.pay_start_time = this.dateRange2[0]
        param.pay_end_time = this.dateRange2[1]
      }
      this.$api.order.orderIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    copyTxt(txt) {
      var _this = this
      var clipboard = new ClipboardJS('#copyId', {
        text: function () {
          return txt;
        },
      });
      clipboard.on('success', function (e) {
        _this.success('复制成功')
      });
      clipboard.on('error', function (e) {
        _this.fail('复制失败')
      });
    },
    orderCancel(id) {
      var _this = this
      this.$api.order.orderCancel({id: id}, res => {
        if (res.errcode == 0) {
          _this.success('取消成功')
          _this.getList()
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    sendDelivery(item) {
      this.delivery.order_id = item.id
      this.dialogVisible = true
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    },
    orderDetail(item) {
      this.isOpen = true
      window.open('/order/detail/'+item.id)
    },
    orderRefundDetail(item) {
      this.isOpen = true
      window.open('/order/refund/detail/'+item.id)
    }
  }
};
</script>
<style scoped>
.gdtable tr {
  background-color: unset;
  border-bottom: 1px solid #f5f5f5
}
.gdtable tr:last-child {
  border-bottom: none !important;
}
.gdtable td {
  padding: 5px 0;
}
.member-box {
  display: flex;
  align-items: center;
  text-align: left;
}
.member-box img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
